<script lang="ts" setup>
import { ContactPerson, MediaImageFragment, ParagraphContactPerson } from '~/api/graphql/generated'

const props = defineProps<{
  entity: ParagraphContactPerson
}>()
const persons =
  props.entity.fieldContactPerson
    ?.filter((person) => person?.entity !== null)
    .map((person) => ({
      name: (person?.entity as ContactPerson).title || '',
      image: (person?.entity as ContactPerson).fieldImage?.entity as MediaImageFragment,
      position: (person?.entity as ContactPerson).fieldTitle || '',
      link: {
        url: (person?.entity as ContactPerson).fieldCta?.uri || '',
        text: (person?.entity as ContactPerson).fieldCta?.title || '',
      },
    })) || []
</script>

<template>
  <ToccoContactPersons :title="entity?.fieldTitle || ''" :persons="persons" />
</template>
